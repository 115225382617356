import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserCreate from '../components/users/UserCreate';
import UserLogin from '../components/users/UserLogin';
import UserPage from '../components/users/UserPage';
import UserList from '../components/users/UserList';
import EditUser from '../components/users/EditUser'; 

function UserRoutes() {
  return (
    <Routes>
      <Route path="/user/create" element={<UserCreate />} />
      <Route path="/user/login" element={<UserLogin />} />
      <Route path="/user/mypage" element={<UserPage />} /> 
      <Route path="/user/list" element={<UserList />} /> 
      <Route path="/user/edit/:id" element={<EditUser />} /> 
    </Routes>
  );
}

export default UserRoutes;
