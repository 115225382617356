import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, TextField, Button } from '@mui/material';

function QuestionModify() {
  const { question_id } = useParams();
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`/api/question/detail/${question_id}`)
      .then(response => {
        setSubject(response.data.subject);
        setContent(response.data.content);
      })
      .catch(err => {
        console.error(err); // 오류를 콘솔에 출력해 디버깅 용도로 사용
      });
  }, [question_id]);

  const updateQuestion = (event) => {
    event.preventDefault();
    const url = '/api/question/update';
    const params = {
      question_id: question_id,
      subject: subject,
      content: content,
    };

    axios.put(url, params)
      .then(() => {
        navigate(`/detail/${question_id}`);
      })
      .catch((err) => {
        console.error(err); // 오류를 콘솔에 출력해 디버깅 용도로 사용
      });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Helmet>
        <title>질문 수정</title>
        <meta name="description" content="기존 질문의 제목과 내용을 수정할 수 있는 페이지입니다." />
      </Helmet>
      <Typography variant="h5" component="h1" gutterBottom>
        질문 수정
      </Typography>
      <Box component="form" onSubmit={updateQuestion} sx={{ mt: 2 }}>
        <TextField
          label="제목"
          fullWidth
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          margin="normal"
        />
        <TextField
          label="내용"
          fullWidth
          variant="outlined"
          multiline
          rows={10}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          수정하기
        </Button>
      </Box>
    </Container>
  );
}

export default QuestionModify;
