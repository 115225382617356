import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, TextField, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function NetSalaryCalculator() {
  const [annualSalary, setAnnualSalary] = useState('');
  const [taxRate, setTaxRate] = useState('');
  const [pensionRate, setPensionRate] = useState('');
  const [healthInsuranceRate, setHealthInsuranceRate] = useState('');
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateSalary = async () => {
    const requestData = {
      annual_salary: parseFloat(annualSalary),
      tax_rate: parseFloat(taxRate),
      pension_rate: parseFloat(pensionRate),
      health_insurance_rate: parseFloat(healthInsuranceRate),
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/salary`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Calculation failed');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>세후 연봉 계산기 - 모두의 도구</title>
        <meta name="description" content="연봉과 세율, 국민연금, 건강보험 비율을 입력하여 세후 연봉을 계산하는 연봉 계산기입니다." />
        <meta property="og:title" content="세후 연봉 계산기 - 모두의 도구" />
        <meta property="og:description" content="세율과 연금 및 보험 공제를 반영한 예상 세후 연봉을 확인하세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        세후 연봉 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          label="연봉 (원)"
          variant="outlined"
          value={annualSalary}
          onChange={(e) => setAnnualSalary(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="세율 (%)"
          variant="outlined"
          value={taxRate}
          onChange={(e) => setTaxRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="국민연금 비율 (%)"
          variant="outlined"
          value={pensionRate}
          onChange={(e) => setPensionRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="건강보험 비율 (%)"
          variant="outlined"
          value={healthInsuranceRate}
          onChange={(e) => setHealthInsuranceRate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={calculateSalary}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="총 연봉" secondary={`${result.gross_salary.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="세금 공제" secondary={`${result.tax_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="국민연금 공제" secondary={`${result.pension_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="건강보험 공제" secondary={`${result.health_insurance_deduction.toLocaleString()} 원`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="세후 연봉" secondary={`${result.net_salary.toLocaleString()} 원`} />
            </ListItem>
          </List>
        </Box>
      )}
    </Container>
  );
}

export default NetSalaryCalculator;
