import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { marked } from 'marked';
import moment from 'moment';
import 'moment/locale/ko';
import { AuthContext } from '../../context/AuthContext';
import {
  Card, CardContent, CardActions, Button, TextField, Typography, Box, Divider, Avatar, List, ListItem, ListItemAvatar, ListItemText
} from '@mui/material';

moment.locale('ko');

function QuestionDetail() {
  const { id } = useParams();
  const [question, setQuestion] = useState({ answers: [], voter: [], content: '' });
  const [content, setContent] = useState('');
  const { isLogin, username, accessToken, userGrade } = useContext(AuthContext); // 관리자 권한 추가
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const getQuestion = useCallback(() => {
    axios.get(`${API_URL}/api/question/detail/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })
      .then(response => {
        setQuestion(response.data);
      })
      .catch(err => {
        console.error("Failed to fetch question detail:", err);
      });
  }, [API_URL, id, accessToken]);

  useEffect(() => {
    getQuestion();
  }, [getQuestion]);

  const postAnswer = (event) => {
    event.preventDefault();
    axios.post(`${API_URL}/api/answer/create/${id}`, { content }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })
      .then(() => {
        setContent('');
        getQuestion();
      })
      .catch(err => {
        console.error("Failed to post answer:", err);
      });
  };

  const deleteQuestion = (question_id) => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      axios.delete(`${API_URL}/api/question/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { question_id }
      })
        .then(() => {
          navigate('/question');
        })
        .catch(err => {
          console.error("Failed to delete question:", err);
        });
    }
  };

  const deleteAnswer = (answer_id) => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      axios.delete(`${API_URL}/api/answer/delete`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { answer_id }
      })
        .then(() => {
          getQuestion();
        })
        .catch(err => {
          console.error("Failed to delete answer:", err);
        });
    }
  };

  const voteQuestion = (question_id) => {
    if (window.confirm('정말로 추천하시겠습니까?')) {
      axios.post(`${API_URL}/api/question/vote`, { question_id }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      })
        .then(() => {
          getQuestion();
        })
        .catch(err => {
          console.error("Failed to vote question:", err);
        });
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Helmet>
        <title>{question.subject ? `${question.subject} - 질문 상세` : "질문 상세"}</title>
        <meta name="description" content={`${question.subject}에 대한 질문 상세와 답변 내용을 확인할 수 있습니다.`} />
      </Helmet>
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>{question.subject}</Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: marked.parse(question.content) }} />
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="caption" color="text.secondary">
            {question.user ? question.user.username : ""} | {moment(question.create_date).format("YYYY년 MM월 DD일 HH:mm")}
          </Typography>
          {question.modify_date && (
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
              수정됨: {moment(question.modify_date).format("YYYY년 MM월 DD일 HH:mm")}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button onClick={() => voteQuestion(question.id)} variant="outlined">
            추천 <span className="badge rounded-pill bg-success">{question.voter.length}</span>
          </Button>
          {question.user && (username === question.user.username || userGrade === 'admin') && (
            <>
              <Link to={`/question/modify/${question.id}`}>
                <Button variant="outlined">수정</Button>
              </Link>
              <Button onClick={() => deleteQuestion(question.id)} variant="outlined">삭제</Button>
            </>
          )}
        </CardActions>
      </Card>

      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6" gutterBottom>{question.answers.length}개의 답변이 있습니다.</Typography>
        <List>
          {question.answers.map((answer) => (
            <ListItem key={answer.id} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>{answer.user ? answer.user.username[0].toUpperCase() : ''}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={answer.user ? answer.user.username : ''}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.primary">
                      {moment(answer.create_date).format("YYYY년 MM월 DD일 HH:mm")}
                    </Typography>
                    <span dangerouslySetInnerHTML={{ __html: marked.parse(answer.content) }} />
                    {answer.modify_date && (
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                        수정됨: {moment(answer.modify_date).format("YYYY년 MM월 DD일 HH:mm")}
                      </Typography>
                    )}
                    {answer.user && (username === answer.user.username || userGrade === 'admin') && (
                      <Box sx={{ marginTop: 1 }}>
                        <Link to={`/answer/update/${answer.id}`}>
                          <Button variant="outlined" size="small">수정</Button>
                        </Link>
                        <Button variant="outlined" size="small" onClick={() => deleteAnswer(answer.id)}>삭제</Button>
                      </Box>
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ marginTop: 3 }}>
        <form onSubmit={postAnswer}>
          <TextField
            label="답변 내용"
            multiline
            rows={3}
            fullWidth
            value={content}
            onChange={(e) => setContent(e.target.value)}
            disabled={!isLogin}
            sx={{ marginBottom: 2 }}
          />
          <Button type="submit" variant="contained" color="primary" disabled={!isLogin}>
            답변 등록
          </Button>
        </form>
      </Box>

      <Button sx={{ marginTop: 3 }} variant="outlined" onClick={() => navigate('/question')}>
        목록으로
      </Button>
    </Box>
  );
}

export default QuestionDetail;
