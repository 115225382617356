import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // react-helmet import 추가
import { Container, TextField, Select, MenuItem, Button, Typography, Box, List, ListItem, ListItemText, FormControl, InputLabel } from '@mui/material';

function CalorieCalculator() {
  const [gender, setGender] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [age, setAge] = useState('');
  const [activityLevel, setActivityLevel] = useState('');
  const [weightChange, setWeightChange] = useState(0);
  const [months, setMonths] = useState(1);
  const [result, setResult] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const calculateCalories = async () => {
    const requestData = {
      gender,
      weight: parseFloat(weight),
      height: parseFloat(height),
      age: parseInt(age, 10),
      activity_level: parseFloat(activityLevel),
      weight_change: parseFloat(weightChange),
      months: parseInt(months, 10)
    };

    try {
      const response = await fetch(`${API_URL}/api/tool/calculator/calories`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('칼로리 계산에 실패했습니다.');
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>칼로리 계산기 - 모두의 도구</title>
        <meta name="description" content="체중, 키, 나이, 활동 수준을 기반으로 칼로리 섭취량을 계산해주는 칼로리 계산기입니다." />
        <meta property="og:title" content="칼로리 계산기 - 모두의 도구" />
        <meta property="og:description" content="체중 변화 목표와 활동 수준에 맞는 칼로리 섭취량을 계산해 보세요." />
      </Helmet>
      
      <Typography variant="h4" gutterBottom>
        칼로리 계산기
      </Typography>

      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>성별</InputLabel>
          <Select value={gender} onChange={(e) => setGender(e.target.value)}>
            <MenuItem value="male">남성</MenuItem>
            <MenuItem value="female">여성</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="체중 (kg)"
          variant="outlined"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="키 (cm)"
          variant="outlined"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="나이 (년)"
          variant="outlined"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>활동 수준</InputLabel>
          <Select value={activityLevel} onChange={(e) => setActivityLevel(e.target.value)}>
            <MenuItem value={1.2}>매우 낮음</MenuItem>
            <MenuItem value={1.375}>낮음</MenuItem>
            <MenuItem value={1.55}>보통</MenuItem>
            <MenuItem value={1.725}>높음</MenuItem>
            <MenuItem value={1.9}>매우 높음</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>체중 변화 목표 (kg)</InputLabel>
          <Select value={weightChange} onChange={(e) => setWeightChange(e.target.value)}>
            {[...Array(21)].map((_, i) => {
              const value = i - 10;
              return value !== 0 ? (
                <MenuItem key={value} value={value}>
                  {value > 0 ? `+${value}` : value} kg
                </MenuItem>
              ) : null;
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>목표 기간 (개월)</InputLabel>
          <Select value={months} onChange={(e) => setMonths(e.target.value)}>
            {[...Array(12)].map((_, i) => (
              <MenuItem key={i + 1} value={i + 1}>{i + 1}개월</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={calculateCalories}>
          계산하기
        </Button>
      </Box>

      {result && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5">계산 결과</Typography>
          <List>
            <ListItem>
              <ListItemText primary="기초대사량 (BMR)" secondary={`${result.bmr.toLocaleString()} kcal`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="일 평균 소모 칼로리" secondary={`${result.daily_calories.toLocaleString()} kcal`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="일일 조정 필요 칼로리"
                secondary={`${Math.sign(result.daily_calories_adjustment) > 0 ? '+' : ''}${result.daily_calories_adjustment.toLocaleString()} kcal`}
              />
            </ListItem>
          </List>
        </Box>
      )}
      <Typography variant="body2" color="textSecondary" align="left" sx={{ mt: 1, fontWeight: 'bold' }}>
        * 일반적인 참고용으로 계산된 결과이며, 개개인에 따라 다를 수 있습니다.
      </Typography>
    </Container>
  );
}

export default CalorieCalculator;
