import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, TextField, Button, Paper, CircularProgress, Alert } from '@mui/material';

function OfficeTextCompare() {
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [result, setResult] = useState('');
  const [isResultVisible, setIsResultVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!text1 || !text2) {
      setErrorMessage('텍스트 1과 텍스트 2를 모두 입력해주세요.');
      return;
    }

    setLoading(true);
    setIsResultVisible(false); // 새로운 비교 요청 시 결과 숨기기
    setErrorMessage(null); // 새로운 비교 요청 시 에러 메시지 초기화

    const formData = new FormData();
    formData.append('text1', text1);
    formData.append('text2', text2);

    try {
      const response = await fetch(`${API_URL}/api/tool/office/office-text-compare`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('텍스트 비교 중 오류가 발생했습니다.');
      }

      const data = await response.json();

      if (data.result) {
        setResult(data.result);
        setIsResultVisible(true);
      }
      if (data.flash) {
        alert(data.flash.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Helmet>
        <title>텍스트 비교 - 모두의 도구</title>
        <meta name="description" content="두 텍스트 파일의 차이점을 비교하여 결과를 보여주는 도구입니다." />
        <meta property="og:title" content="텍스트 비교 - 모두의 도구" />
        <meta property="og:description" content="두 텍스트 파일의 차이점을 쉽게 비교해보세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        텍스트 비교
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        * 줄바꿈은 무시됩니다.
      </Typography>

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMessage}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
          <TextField
            label="텍스트 1"
            multiline
            rows={20}
            fullWidth
            required
            value={text1}
            onChange={(e) => setText1(e.target.value)}
          />
          {isResultVisible ? (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                minHeight: 320,
                whiteSpace: 'pre-wrap',
                overflowY: 'auto',
              }}
              dangerouslySetInnerHTML={{ __html: result }}
            />
          ) : (
            <TextField
              label="텍스트 2"
              multiline
              rows={20}
              fullWidth
              required
              value={text2}
              onChange={(e) => setText2(e.target.value)}
            />
          )}
        </Box>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : '비교하기'}
        </Button>
      </Box>
    </Container>
  );
}

export default OfficeTextCompare;
