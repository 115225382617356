import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, Button, TextField, CircularProgress, Snackbar } from '@mui/material';

function ImageBackgroundRemove() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_URL || 'https://default-api-url.com';

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setError('이미지를 업로드해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/tool/image/image-background-remove`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('변환 중 오류가 발생했습니다.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'image_with_transparent_background.png';
      document.body.appendChild(a);
      a.click();
      a.remove();
      setFile(null); // 파일 선택 초기화
    } catch (error) {
      console.error('Error:', error);
      setError('이미지 변환에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>이미지 배경 제거 - 모두의 도구</title>
        <meta name="description" content="업로드한 이미지에서 배경을 제거하고 투명한 배경 이미지를 다운로드하는 도구입니다." />
        <meta property="og:title" content="이미지 배경 제거 - 모두의 도구" />
        <meta property="og:description" content="이미지에서 배경을 제거하여 투명한 배경으로 변환하세요." />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        이미지 배경 제거
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          type="file"
          fullWidth
          inputProps={{ accept: 'image/*' }}
          onChange={handleFileChange}
          variant="outlined"
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : '변환 및 다운로드'}
        </Button>
      </Box>

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Container>
  );
}

export default ImageBackgroundRemove;
