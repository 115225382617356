import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ko';
import { AuthContext } from '../../context/AuthContext';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Pagination, Box, Typography
} from '@mui/material';

export default function QuestionIndex() {
  moment.locale('ko');
  const { isLogin } = useContext(AuthContext);
  const [questionList, setQuestionList] = useState([]);
  const [size] = useState(10);
  const [total, setTotal] = useState(0);
  const [kw, setKw] = useState('');
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const totalPage = Math.ceil(total / size);

  const getQuestionList = useCallback(async () => {
    const params = {
      page,
      size,
      keyword
    };
    try {
      const response = await axios.get(`${API_URL}/api/question`, { params });
      const json = response.data;
      setQuestionList(json.question_list);
      setTotal(json.total);
      setKw(keyword);
    } catch (error) {
      console.error("Failed to fetch question list:", error);
    }
  }, [page, size, keyword, API_URL]);

  useEffect(() => {
    getQuestionList();
  }, [getQuestionList]);

  const handleSearch = () => {
    setKeyword(kw);
    setPage(0);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage - 1);
  };

  const handleQuestionCreate = () => {
    if (isLogin) {
      navigate('/question/create');
    } else {
      navigate('/user/login');
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Helmet>
        <title>질문 목록</title>
        <meta name="description" content="사용자가 등록한 질문 목록을 확인하고 검색할 수 있는 페이지입니다." />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Button variant="contained" color="primary" onClick={handleQuestionCreate}>
          질문 등록하기
        </Button>
        <Box display="flex" alignItems="center">
          <TextField
            label="검색어"
            variant="outlined"
            size="small"
            value={kw}
            onChange={(e) => setKw(e.target.value)}
            sx={{ marginRight: 1 }}
          />
          <Button variant="outlined" onClick={handleSearch}>
            찾기
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">번호</TableCell>
              <TableCell align="left" style={{ width: '50%' }}>제목</TableCell>
              <TableCell align="center">글쓴이</TableCell>
              <TableCell align="center">작성일시</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  등록된 질문이 없습니다.
                </TableCell>
              </TableRow>
            ) : (
              questionList.map((question, i) => (
                <TableRow key={question.id}>
                  <TableCell align="center">{total - (page * size) - i}</TableCell>
                  <TableCell>
                    <Link to={`detail/${question.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {question.subject}
                    </Link>
                    {question.answers && question.answers.length > 0 && (
                      <Typography variant="caption" color="error" sx={{ marginLeft: 1 }}>
                        {question.answers.length}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">{question.user ? question.user.username : ""}</TableCell>
                  <TableCell align="center">{moment(question.create_date).format("YYYY년 MM월 DD일 HH:mm")}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" marginTop={3}>
        <Pagination
          count={totalPage}
          page={page + 1}
          onChange={handlePageChange}
          variant="outlined"
          color="primary"
        />
      </Box>
    </Box>
  );
}
