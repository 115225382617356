import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // 기본 스타일 리셋
import MainLayout from './components/layout/MainLayout';
import QuestionRoutes from './routes/QuestionRoutes';
import UserRoutes from './routes/UserRoutes';
import MainRoutes from './routes/MainRoutes';
import ToolRoutes from './routes/ToolRoutes';
import { AuthProvider } from './context/AuthContext';

// 기본 테마 생성
const theme = createTheme({
  palette: {
    primary: {
      // main: '#6197e7',
      main: '#273F44',
    },
    secondary: {
      main: '#dc004e', 
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <MainLayout>
            <ToolRoutes />
            <QuestionRoutes />
            <MainRoutes />
            <UserRoutes />
          </MainLayout>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
