import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ToolMain from '../components/tools/ToolMain';
// 카테고리별 메인 컴포넌트
import SearchMain from '../components/tools/SearchMain';
import SmartstoreMain from '../components/tools/SmartstoreMain';
import OfficeMain from '../components/tools/OfficeMain';
import CalculatorMain from '../components/tools/CalculatorMain';
import ImageMain from '../components/tools/ImageMain';
// 계산기 세부 컴포넌트
import AcquisitionTaxCalculator from '../components/tools/calculator/AcquisitionTaxCalculator';
import CalorieCalculator from '../components/tools/calculator/CalorieCalculator';
import CarInstallmentCalculator from '../components/tools/calculator/CarInstallmentCalculator';
import CarRegistrationTaxCalculator from '../components/tools/calculator/CarRegistrationTaxCalculator';
import DebtRepaymentCalculator from '../components/tools/calculator/DebtRepaymentCalculator';
import DepositCalculator from '../components/tools/calculator/DepositCalculator';
import EducationCostCalculator from '../components/tools/calculator/EducationCostCalculator';
import ExpenseManagementCalculator from '../components/tools/calculator/ExpenseManagementCalculator';
import IncomeTaxCalculator from '../components/tools/calculator/IncomeTaxCalculator';
import InsuranceCalculator from '../components/tools/calculator/InsuranceCalculator';
import LoanCalculator from '../components/tools/calculator/LoanCalculator';
import MortgageCalculator from '../components/tools/calculator/MortgageCalculator';
import NetSalaryCalculator from '../components/tools/calculator/NetSalaryCalculator';
import SeveranceCalculator from '../components/tools/calculator/SeveranceCalculator';
import VATCalculator from '../components/tools/calculator/VATCalculator';
import YearEndTaxCalculator from '../components/tools/calculator/YearEndTaxCalculator';
// 이미지 세부 컴포넌트
import ImageBackgroundRemove from '../components/tools/image/ImageBackgroundRemove';
import ImageConvertImageToSvg from '../components/tools/image/ImageConvertImageToSvg';
// 오피스 세부 컴포넌트
import OfficeConvertHwpToDocx from '../components/tools/office/OfficeConvertHwpToDocx';
import OfficeConvertDocxToHwp from '../components/tools/office/OfficeConvertDocxToHwp';
import OfficePdfMerge from '../components/tools/office/OfficePdfMerge';
import OfficePdfSplit from '../components/tools/office/OfficePdfSplit';
import OfficeTextCompare from '../components/tools/office/OfficeTextCompare';
// 검색 세부 컴포넌트
import Bing from '../components/tools/search/Bing';
import Google from '../components/tools/search/Google';
import Naver from '../components/tools/search/Naver';
// 스마트스토어 세부 컴포넌트
import BlogMyStore from '../components/tools/smartstore/BlogMyStore';
import ProductList from '../components/tools/smartstore/ProductList';
import RankingMyStore from '../components/tools/smartstore/RankingMyStore';
import SearchKeyword from '../components/tools/smartstore/SearchKeyword';

function ToolRoutes() {
  return (
    <Routes>
      {/* 메인 페이지 */}
      <Route path="/tool" element={<ToolMain />} />
      
      {/* 카테고리별 메인 페이지 */}
      <Route path="/tool/search" element={<SearchMain />} />
      <Route path="/tool/smartstore" element={<SmartstoreMain />} />
      <Route path="/tool/office" element={<OfficeMain />} />
      <Route path="/tool/calculator" element={<CalculatorMain />} />
      <Route path="/tool/image" element={<ImageMain />} />
      
      {/* 검색 세부 경로 */}
      <Route path="/tool/search/bing" element={<Bing />} />
      <Route path="/tool/search/google" element={<Google />} />
      <Route path="/tool/search/naver" element={<Naver />} />
      
      {/* 오피스 세부 경로 */}
      <Route path="/tool/office/pdf-merge" element={<OfficePdfMerge />} />
      <Route path="/tool/office/pdf-split" element={<OfficePdfSplit />} />
      <Route path="/tool/office/text-compare" element={<OfficeTextCompare />} />
      <Route path="/tool/office/oconvert-hwp-to-docx" element={<OfficeConvertHwpToDocx />} />  
      <Route path="/tool/office/convert-docx-to-hwp" element={<OfficeConvertDocxToHwp />} />          

      {/* 스마트스토어 세부 경로 */}
      <Route path="/tool/smartstore/blog-mystore" element={<BlogMyStore />} />
      <Route path="/tool/smartstore/product-list" element={<ProductList />} />
      <Route path="/tool/smartstore/ranking-mystore" element={<RankingMyStore />} />
      <Route path="/tool/smartstore/search-keyword" element={<SearchKeyword />} />

      {/* 이미지 세부 경로 */}
      <Route path="/tool/image/image-background-remove" element={<ImageBackgroundRemove />} />
      <Route path="/tool/image/image-convert-to-svg" element={<ImageConvertImageToSvg />} />

      {/* 계산기 세부 경로 */}
      <Route path="/tool/calculator/acquisition-tax" element={<AcquisitionTaxCalculator />} />
      <Route path="/tool/calculator/calories" element={<CalorieCalculator />} />
      <Route path="/tool/calculator/car-installment" element={<CarInstallmentCalculator />} />
      <Route path="/tool/calculator/car-registration-tax" element={<CarRegistrationTaxCalculator />} />
      <Route path="/tool/calculator/debt-repayment" element={<DebtRepaymentCalculator />} />
      <Route path="/tool/calculator/deposit" element={<DepositCalculator />} />
      <Route path="/tool/calculator/education-cost" element={<EducationCostCalculator />} />
      <Route path="/tool/calculator/expenses" element={<ExpenseManagementCalculator />} />
      <Route path="/tool/calculator/income-tax" element={<IncomeTaxCalculator />} />
      <Route path="/tool/calculator/insurance" element={<InsuranceCalculator />} />
      <Route path="/tool/calculator/loan" element={<LoanCalculator />} />
      <Route path="/tool/calculator/mortgage" element={<MortgageCalculator />} />
      <Route path="/tool/calculator/salary" element={<NetSalaryCalculator />} />
      <Route path="/tool/calculator/severance" element={<SeveranceCalculator />} />
      <Route path="/tool/calculator/vat" element={<VATCalculator />} />
      <Route path="/tool/calculator/year-end-tax" element={<YearEndTaxCalculator />} />
    </Routes>
  );
}

export default ToolRoutes;
