import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Typography } from '@mui/material';

function UserPage() {
  const { username, isLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate('/user/login'); 
    }
  }, [isLogin, navigate]);

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Helmet>
        <title>마이페이지 - 사이트 이름</title>
      </Helmet>
      <Typography variant="h4" component="h1" gutterBottom>
        마이페이지
      </Typography>
      <Typography variant="body1">
        환영합니다, {username}님!
      </Typography>
    </Container>
  );
}

export default UserPage;
